<template>
  <v-card
    v-if="!toRemove"
    :class="{ 'grey-scale': toRemove == true }"
    class="my-2 selected-card"
  >
    <v-sheet
      v-if="alert.show"
      color="error"
      class="d-flex align-center justify-center py-2"
    >
      <v-icon color="white">mdi-alert</v-icon>
      <span
        class="white--text ml-2"
        v-html="alert.text"
      ></span>
    </v-sheet>
    <v-row
      class="pa-2"
      dense
    >
      <!------------------------------------
        Cabeçalho em forma de tabela
      ------------------------------------->
      <v-col
        class="secondary--text"
        cols="12"
      >
        <v-simple-table dense>
          <template v-slot:default>
            <thead>
              <tr>
                <th
                  v-for="(item, idx) in tableItems"
                  :key="idx"
                  :style="{ 'min-width': item.width, 'text-align': item.align }"
                >
                  {{ item.title }}
                </th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td style="width: 70px">
                  <v-avatar
                    class="elevation-3"
                    size="75"
                    rounded
                  >
                    <v-img
                      v-if="
                        selectedProduct.product_images &&
                        selectedProduct.product_images.length > 0
                      "
                      :src="
                        $store.state.imagePath +
                        selectedColor.product_image_path
                      "
                    />
                    <v-img
                      v-else-if="selectedProduct.temp_image"
                      :src="$store.state.imagePath + selectedProduct.temp_image"
                    />
                    <v-icon v-else> fas fa-image </v-icon>
                  </v-avatar>
                </td>
                <td
                  class="text-caption"
                  style="min-width: 50px"
                >
                  <div>{{ selectedProduct.product_name }}</div>
                  <div>
                    {{
                      selectedProduct.product_reference
                        ? selectedProduct.product_reference
                        : selectedProduct.product_id
                    }}
                  </div>
                  <div>
                    {{
                      selectedProduct.is_temporary
                        ? 'Temporário'
                        : 'NCM: ' +
                          (selectedProduct.ncm?.code ||
                            'Produto não possui NCM vinculado.')
                    }}
                  </div>
                </td>
                <td class="text-caption text-center">
                  <v-menu
                    v-if="!selectedProduct.is_temporary"
                    open-on-hover
                    offset-y
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <span
                        v-on="on"
                        v-bind="attrs"
                        class="primary--text text-decoration-underline"
                      >
                        <v-avatar
                          color="primary"
                          size="40"
                        >
                          <v-img
                            v-if="
                              productColors &&
                              productColors.length > 0 &&
                              selectedColor.color_image_path
                            "
                            :src="
                              $store.state.imagePath +
                              selectedColor.color_image_path
                            "
                          />
                          <v-icon
                            v-else
                            color="white"
                            >mdi-palette</v-icon
                          >
                        </v-avatar>
                      </span>
                    </template>
                    <v-card
                      class="overflow-auto"
                      max-height="180"
                    >
                      <v-list dense>
                        <v-list-item
                          v-for="(item, idx) in productColors"
                          :key="idx"
                          @click="selectColor(item)"
                        >
                          <v-list-item-avatar size="20">
                            <v-img
                              v-if="item.color.image_color"
                              :src="
                                $store.state.imagePath + item.color.image_color
                              "
                            />
                            <v-icon v-else>mdi-palette</v-icon>
                          </v-list-item-avatar>
                          <v-list-item-title class="caption">
                            {{ item.color.name }}
                          </v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-card>
                  </v-menu>
                  <span v-else>-</span>
                </td>
                <td class="text-caption text-center">
                  <div
                    v-if="
                      !selectedProduct.is_temporary && selectedProduct.vendor
                    "
                  >
                    <v-chip
                      class="caption font-weight-bold"
                      :color="switchColor(selectedProduct.vendor.vendor_status)"
                      x-small
                      dark
                    >
                      {{
                        checkVendorStatus(selectedProduct.vendor.vendor_status)
                      }}
                    </v-chip>
                    <div>
                      {{
                        selectedProduct.vendor.updated_at
                          .slice(0, 10)
                          .split('-')
                          .reverse()
                          .join('/')
                      }}
                    </div>
                  </div>
                  <span
                    v-else
                    v-text="'-'"
                  />
                </td>
                <td class="text-caption text-center">
                  <v-menu
                    v-if="!selectedProduct.is_temporary"
                    open-on-hover
                    offset-y
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <span
                        v-on="on"
                        v-bind="attrs"
                        class="primary--text text-decoration-underline font-weight-bold"
                      >
                        {{
                          selectedPrint.title
                            ? selectedPrint.title
                            : selectedPrint.name
                        }}
                        <v-icon color="primary">mdi-menu-down</v-icon>
                      </span>
                    </template>
                    <v-card
                      class="overflow-auto"
                      max-height="150"
                    >
                      <v-list dense>
                        <v-list-item
                          v-for="(item, idx) in productsPrints"
                          :key="idx"
                          @click="selectPrint(item)"
                        >
                          <v-icon
                            color="primary"
                            v-if="item < 6"
                            >mdi-table</v-icon
                          >
                          <v-list-item-title class="caption ml-1">
                            <v-icon
                              v-if="item.printed"
                              color="primary"
                              >mdi-table</v-icon
                            >
                            {{ item.name ? item.name : item.title }}
                          </v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-card>
                  </v-menu>
                  <span v-else>-</span>
                </td>
                <td class="text-caption text-center">
                  <v-menu
                    open-on-hover
                    offset-y
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <span
                        v-on="on"
                        v-bind="attrs"
                        class="primary--text text-decoration-underline font-weight-bold"
                      >
                        {{ selectedCompany }}
                        <v-icon color="primary">mdi-menu-down</v-icon>
                      </span>
                    </template>
                    <v-card
                      class="overflow-auto"
                      max-height="150"
                    >
                      <v-list dense>
                        <v-list-item
                          v-for="(item, idx) in companies"
                          :key="idx"
                          @click="selectCompany(item)"
                        >
                          <v-list-item-title class="caption">{{
                            item.company_name
                          }}</v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-card>
                  </v-menu>
                </td>
                <td class="text-right">
                  <v-btn
                    icon
                    @click="deleteProduct"
                  >
                    <v-icon color="error">mdi-close</v-icon>
                  </v-btn>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-col>

      <v-col cols="6">
        <section
          class="overflow-auto"
          style="height: 151px; border: 2px solid rgb(209, 209, 209)"
        >
          <v-list
            v-if="selectedProduct.rows.length > 0"
            dense
            :clickable="false"
          >
            <v-list-item
              v-for="(row, idx) in selectedProduct.rows"
              :key="idx"
              :ripple="false"
            >
              <template v-slot:default="{ active }">
                <v-list-item-action>
                  <v-checkbox
                    dense
                    v-model="row.is_check"
                    :input-value="active"
                  />
                </v-list-item-action>

                <v-list-item-content>
                  <v-row
                    align="center"
                    dense
                  >
                    <v-col
                      cols="12"
                      md="3"
                    >
                      <v-text-field
                        @click.stop
                        v-model.number="row.quantity"
                        class="d-flex align-center"
                        label="Qtd."
                        placeholder="000"
                        outlined
                        rounded
                        dense
                        hide-details
                      />
                    </v-col>
                    <v-col
                      cols="12"
                      md="6"
                    >
                      <v-text-field
                        v-model="row.unitary_value"
                        @input="(e) => setManualUnitaryValue(row, e)"
                        @click.stop
                        class="d-flex align-center"
                        label="Valor UN"
                        v-mask="[
                          '#,###',
                          '##,###',
                          '###,###',
                          '####,###',
                          '#####,###',
                          '######,###',
                        ]"
                        placeholder="0,00"
                        outlined
                        rounded
                        dense
                        hide-details
                      >
                        <template v-slot:append>
                          <span style="line-height: 25px">
                            {{ unitarySuffix(row) }}
                          </span>
                        </template>
                      </v-text-field>
                    </v-col>
                    <v-col
                      cols="12"
                      md="3"
                    >
                      <span
                        >Total:
                        {{ calculatePrice(row).total }}
                      </span>
                    </v-col>
                  </v-row>
                </v-list-item-content>
                <v-list-item-action>
                  <v-btn
                    color="error"
                    class="ml-2"
                    icon
                    dark
                  >
                    <v-icon
                      @click="() => removeRow(idx)"
                      small
                      >fas fa-times</v-icon
                    >
                  </v-btn>
                </v-list-item-action>
              </template>
            </v-list-item>
          </v-list>
          <h6
            v-else
            class="text-center text-h6 grey--text my-14"
          >
            Nenhuma tabela adicionada
          </h6>
        </section>
        <v-col
          align="left"
          class="pb-0"
        >
          <v-btn
            @click="addRow"
            color="primary"
            rounded
            small
          >
            <span>Adicionar tabela</span>
            <v-icon
              small
              right
              >fas fa-plus</v-icon
            >
          </v-btn>
        </v-col>
      </v-col>

      <!------------------------------------
        Rich Text
      ------------------------------------->
      <v-col cols="6">
        <ckeditor
          v-model="productDescription"
          id="richText"
          :config="editorConfig"
          label="Observações"
          rounded
          dense
          outlined
        />
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import { calculate } from '@/main.js'

export default {
  props: {
    product: { type: Object, default: () => {} },
    defaultCompanyId: { type: Number, default: () => null },
    companies: { type: Array, required: true },
    index: { type: Number, required: true },
    disableCard: Boolean,
  },
  data: () => ({
    number: 0,
    tweenedNumber: 0,
    selectedProduct: {
      product_id: null,
      color_id: null,
      print_id: null,
      vendor_finance_id: null,
      invoicing_id: null,
      is_temporary: false,
      rows: [],
      teste: 'fade',
    },
    showTable: false,
    toRemove: false,
    tableItems: [
      { title: 'Imagem', align: 'left', width: '50px' },
      { title: 'Produto', align: 'left' },
      { title: 'Cor', align: 'center' },
      { title: 'Status Forn.', align: 'center' },
      { title: 'Gravação', align: 'center' },
      { title: 'Faturamento', align: 'center' },
    ],
    alert: {
      show: false,
      text: '',
    },
    editorConfig: {
      toolbar: [['Bold', 'Italic', 'Strike', 'Styles', 'Format']],
    },
  }),
  watch: {
    selectedProduct: {
      handler(newvalue) {
        this.selectedProduct.product_id = newvalue.id
          ? newvalue.id
          : newvalue.product_id
        this.selectedProduct.is_temporary = newvalue.is_temporary
        this.selectedProduct.invoicing_id = newvalue.invoicing_id
          ? newvalue.invoicing_id
          : !newvalue.is_temporary
          ? this.companies[0].id
          : null
        this.selectedProduct.color_id = newvalue.color_id
          ? newvalue.color_id
          : !newvalue.is_temporary
          ? this.productColors[0].color_id
          : null
        // this.selectedProduct.print_id = newvalue.print_id
        //   ? newvalue.print_id
        //   : !newvalue.is_temporary
        //   ? newvalue.prints[0].id
        //   : null
        this.selectedProduct.vendor_finance_id = newvalue.vendor_finance_id
          ? newvalue.vendor_finance_id
          : !newvalue.is_temporary
          ? newvalue.vendor.cost_tables[0].id
          : null

        this.$emit('addBudgetProduct', newvalue)
      },
      deep: true,
    },
    'selectedProduct.rows': {
      handler(newvalue) {
        let checkedCount = 0

        newvalue.map((el) => {
          if (el.is_check && el.is_check != false) {
            checkedCount++
            this.$store.commit('setTotalBudget', {
              cost: el.unitary_cost,
              idx: this.index,
            })
            this.$store.commit('setTotalOrder', {
              totalCost: el.unitary_cost * el.quantity,
              idx: this.index,
            })
          }
        })

        if (checkedCount == 0) {
          this.alert.show = true
          this.alert.text =
            'Para que este orçamento possa ser aprovado, é necessário selecionar <strong><u>1 tabela de valor</u></strong> neste produto.'
        } else if (checkedCount > 1) {
          this.alert.show = true
          this.alert.text =
            'Selecione apenas <strong><u>1 tabela de valor</u></strong> neste produto.'
        } else {
          this.alert.show = false
          this.alert.text = ''
        }
      },
      deep: true,
      immediate: true,
    },
    'alert.show': {
      handler(newvalue) {
        if (newvalue == true) {
          this.$store.commit('setBudgetApproval', { value: 1, idx: this.index })
        } else {
          this.$store.commit('setBudgetApproval', { value: 0, idx: this.index })
        }
      },
      immediate: true,
    },
  },
  computed: {
    productColors() {
      let colors = []
      if (this.selectedProduct.product_images) {
        this.selectedProduct.product_images.map((el) => colors.push(el))
      }

      return colors
    },
    productsPrints() {
      let tables = this.selectedProduct.vendor.cost_tables.filter(
        (el) => el.printed
      )
      if (this.selectedProduct.vendor) {
        return [
          { id: null, title: 'Sem gravação' },
          ...tables,
          ...this.selectedProduct.prints,
        ]
      }
      return [
        { id: null, title: 'Sem gravação', rows: [] },
        ...tables,
        ...this.selectedProduct.prints,
      ]
    },
    selectedColor() {
      if (!this.selectedProduct.is_temporary && this.selectedProduct.color_id) {
        let color_image = this.productColors.find(
          (el) => el.color.id == this.selectedProduct.color_id
        )

        return {
          product_image_path: color_image.src,
          color_image_path: color_image.color.image_color,
        }
      }

      return {
        product_image_path: this.productColors[0].src,
        color_image_path: this.productColors[0].color.image_color,
      }
    },
    selectedPrint() {
      if (
        !this.selectedProduct.is_temporary &&
        this.selectedProduct.vendor_finance_id
      ) {
        let table = this.selectedProduct.vendor.cost_tables.find(
          (el) => el.id == this.selectedProduct.vendor_finance_id
        )
        if (table.printed) {
          return table
        }
      }

      if (!this.selectedProduct.is_temporary && this.selectedProduct.print_id) {
        let print = this.selectedProduct.prints.find(
          (el) => el.id == this.selectedProduct.print_id
        )
        return print
      }

      return this.productsPrints[0]
    },
    selectedCompany() {
      if (this.selectedProduct.invoicing_id) {
        let company = this.companies.find(
          (el) => el.id == this.selectedProduct.invoicing_id
        )
        return company.company_name
      }

      return this.companies[0].company_name
    },
    productDescription: {
      get: function () {
        return this.selectedProduct.observation
          ? this.selectedProduct.observation
          : this.selectedProduct.product_description
      },
      set: function (newvalue) {
        this.selectedProduct.observation = newvalue
      },
    },
  },

  methods: {
    deleteProduct() {
      this.toRemove = true
      this.$store.commit('setProductsToRemove', {
        product_id: this.selectedProduct.product_id,
        color_id: this.selectedProduct.color_id,
      })
      // this.$emit('removeBudgetProduct', this.selectedProduct)
    },

    setManualUnitaryValue(row, e) {
      row.manual_unitary_value = true
      row.unitary_value = e

      if (e == '') {
        row.manual_unitary_value = false
      }
    },
    addRow() {
      this.selectedProduct.rows.push({ manual_unitary_value: false })
    },
    removeRow(idx) {
      this.selectedProduct.rows.splice(idx, 1)
    },
    calculatePrice(row) {
      if (row.manual_unitary_value && !this.selectedProduct.is_temporary) {
        let total = parseFloat(
          (row.unitary_value.replace(',', '.') * row.quantity).toFixed(2)
        )
        let unity = total / row.quantity

        row.unitary_cost = unity

        total = total.toLocaleString('pt-BR', {
          style: 'currency',
          currency: 'BRL',
        })
        unity = parseFloat(unity.toFixed(2)).toLocaleString('pt-BR', {
          style: 'currency',
          currency: 'BRL',
        })

        return {
          total: total,
          unity: unity,
        }
      } else if (this.selectedProduct.is_temporary) {
        let total = row.manual_unitary_value
          ? row.quantity * parseFloat(row.unitary_value.replace(',', '.'))
          : 0
        let unity = row.unitary_value

        row.unitary_cost = total / row.quantity

        total = total.toLocaleString('pt-BR', {
          style: 'currency',
          currency: 'BRL',
        })

        if (unity) {
          unity = parseFloat(unity.replace(',', '.')).toLocaleString('pt-BR', {
            style: 'currency',
            currency: 'BRL',
          })
        }

        return {
          total: total,
          unity: unity,
        }
      } else {
        if (this.selectedPrint.id == null && !row.manual_unitary_value) {
          return {
            total: 'R$ 0,00',
            unity: parseFloat(0).toLocaleString('pt-BR', {
              style: 'currency',
              currency: 'BRL',
            }),
          }
        }

        let total = calculate.price(
          row,
          this.selectedPrint,
          this.selectedProduct.vendor.cost_tables[0],
          this.$store.state.fixedValue,
          this.selectedProduct.factor.rows
        )

        total = total ? total : 0
        let unity = total / row.quantity
        unity = unity ? unity : 0

        row.unitary_cost = unity

        total = total.toLocaleString('pt-BR', {
          style: 'currency',
          currency: 'BRL',
        })
        unity = unity.toLocaleString('pt-BR', {
          style: 'currency',
          currency: 'BRL',
        })

        return {
          total: total,
          unity: unity,
        }
      }
    },
    selectCompany(item) {
      this.selectedProduct.invoicing_id = item.id
    },
    selectColor(item) {
      this.selectedProduct.color_id = item.color.id
    },
    selectPrint(item) {
      if (item.id == null) {
        this.selectedProduct.print_id = null
        this.selectedProduct.vendor_finance_id = null
      }

      if (item.printed) {
        this.selectedProduct.vendor_finance_id = item.id
      } else {
        this.selectedProduct.print_id = item.id
        this.selectedProduct.vendor_finance_id =
          this.selectedProduct.vendor.cost_tables[0].id
      }
    },
    unitarySuffix(row) {
      if (!row.manual_unitary_value) {
        return this.calculatePrice(row).unity
      }
      return '0,00'
    },
    checkVendorStatus(status) {
      if (status === 1) {
        return 'OK'
      } else if (status === 2) {
        return 'Consultar'
      } else if (status === 3) {
        return 'Sem estoque'
      } else if (status === 4) {
        return 'Fora de linha'
      }
    },
    switchColor(status) {
      if (status === 1) {
        return 'success'
      } else if (status === 2) {
        return 'blue'
      } else if (status === 3) {
        return 'error'
      } else if (status === 4) {
        return 'gray'
      }
    },
    startSelectedProducts() {
      var id = null

      if (this.product.product) {
        id = this.product.product.id
      }

      this.selectedProduct = {
        ...this.selectedProduct,
        ...this.product,
        ...this.product.product,
      }

      if (this.product.product) {
        this.selectedProduct.id = id
      }

      if (this.defaultCompanyId && !this.selectedProduct.invoicing_id) {
        this.selectedProduct.invoicing_id = this.defaultCompanyId
      }

      this.selectedProduct.budget_product_id = this.product.id
    },
  },
  mounted() {
    this.startSelectedProducts()
  },
}
</script>

<style>
.selected-card {
  transition: all 0.5s;
  border: 2px solid transparent;
}
.selected-card:hover {
  border: 4px solid var(--v-primary-base);
}

#richText div.cke_contents {
  height: auto !important;
}
#richText iframe.cke_wysiwyg_frame {
  max-height: 100px !important;
}
#richText .cke_bottom {
  display: none;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.fade-enter-active,
.fade-leave-active {
  transition: all 0.2s;
}

.fade-enter-to,
.fade-leave {
  opacity: 1;
}

.scale-enter,
.scale-leave-to {
  background-color: aqua;
}

.scale-enter-active,
.scale-leave-active {
  transition: all 0.2s;
}

.scale-enter-to,
.scale-leave {
  background-color: rgb(255, 0, 183);
}

.grey-scale {
  filter: grayscale(1);
}
</style>
